.default-text-color {
  color: black !important;
}

.default-hedge-documentation-cell {
  padding-left: 15px !important;
  vertical-align: middle !important;
  outline: 1px solid black !important;
  outline-offset: -4px !important;
  border: none !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.hedge-documentation-header{
  font-size: 16px;
  font-weight: bold;
  line-height: 75px !important;
  border: none !important;
  outline: none !important;
}

.checkmark {
  position: relative;
  font-size: 24px;
  color: transparent !important;
  background-color: #fff;
  text-align: center;
  line-height: 24px;
}

.checkmark::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 16px;
  border: solid #28a745;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.no-outline {
  outline: none !important;
}

.header {
  font-size: 12px !important;
  font-weight: bold;
  text-align: center !important;
  vertical-align: middle !important;
  background-color: #1FD6FF !important;
}

.title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle !important;
  line-height: 75px !important;
  outline: none !important;
}

.subtitle {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle !important;
}

.footer {
  font-size: 10px;
  line-height: 14px !important;
}

.red-title{
  color: #EB4235 !important;
  font-size: 20px;
  text-align: center;
  vertical-align: middle !important;
  font-weight: bold;
  font-style: italic !important;
}